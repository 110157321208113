<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="true" />
    <PageHeader :title="title" />
    <v-card>
      <v-form
        v-model="isFormValid"
        ref="send-form"
        id="send-form"
        @submit.prevent="saveFormatoConfig()"
        ><v-card-text class="pt-0 pb-0">
          <v-container>
            <v-row>
              <!-- Proceso de exportación -->
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-select
                  v-model="procesoExportacionSelected"
                  :items="procesosExportacion"
                  label="Proceso de exportación"
                  @change="updateProcesoEnvioDetalle()"
                  :rules="rules.required"
                  return-object
                  item-value="id"
                  item-text="value"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
              <v-col
                cols="1"
                sm="1"
                md="1"
                class="pt-2"
                v-if="
                  procesoExportacionSelected != null &&
                  !procesoExportacionSelected.bool
                "
              >
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="primary--text" v-bind="attrs" v-on="on">
                      {{ infoIcon }}
                    </v-icon>
                  </template>
                  <span>
                    El proceso de exportación seleccionado no necesita ser
                    configurado.
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <!-- el bool de procesoExportacionSelected representa si el formato necesita ser configurado o no -->
                <v-data-table
                  outlined
                  dense
                  :hide-default-footer="
                    detalleConfig.length < 50 ? true : false
                  "
                  :header-props="{ sortIcon: null }"
                  :items-per-page="50"
                  :headers="configs"
                  class="elevation-1"
                  :items="detalleConfig"
                  fixed-header
                >
                  <template
                    v-slot:[`item.campoValor`]="{ item }"
                    v-if="
                      procesoExportacionSelected == null ||
                      procesoExportacionSelected.bool
                    "
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          {{ item.campoValor }}
                          <v-icon
                            :color="'primary'"
                            size="16"
                            @click="openModal(item)"
                            >{{ editIcon }}</v-icon
                          >
                        </div>
                      </template>
                      <span>Editar valor</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-dialog
                  v-if="openModalEdit"
                  v-model="openModalEdit"
                  max-width="400px"
                  @keydown.enter="saveEditValor"
                  persistent
                  @keydown.esc="cancelEditValor"
                >
                  <v-card>
                    <v-container>
                      <v-card-title class="headline primary--text py-0">
                        Editar valor
                      </v-card-title>
                      <v-card-text class="pb-0">
                        <v-combobox
                          ref="edit-valor"
                          v-model="campoValorToEdit"
                          :items="!isSeparador ? selectValor : []"
                          single-line
                          item-value="id"
                          item-text="value"
                          label="Editar valor"
                        ></v-combobox>
                      </v-card-text>
                      <v-card-actions class="pt-0">
                        <v-spacer></v-spacer>
                        <v-btn outlined @click="cancelEditValor">
                          Cancelar
                        </v-btn>
                        <v-btn color="primary" @click="saveEditValor">
                          Guardar
                        </v-btn>
                      </v-card-actions>
                    </v-container>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <!-- Tipo valores -->
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-select
                  v-model="tipoValorSelected"
                  :items="tipoValores"
                  label="Tipo de valor"
                  item-text="value"
                  item-value="id"
                  :rules="rules.required"
                  outlined
                  clearable
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            type="submit"
            :disabled="!isFormValid"
            :loading="loadingSaveBtn"
            form="send-form"
          >
            Grabar configuración
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";

export default {
  name: "EditFormatoExportacionTransferencia",
  components: {
    PageHeader,
    GoBackBtn,
  },
  created() {
    this.setSelects();
  },
  mounted() {
    if (this.$route.params.envioConfiguracionEstandarId) {
      this.title = "Editar formato de exportación de transferecias";
      this.envioConfigId = this.$route.params.envioConfiguracionEstandarId;
      this.setFormatoConfigById();
    }
  },
  data() {
    return {
      title: "Nuevo formato de exportación de transferencias",
      editIcon: enums.icons.EDIT,
      selectValor: [],
      infoIcon: enums.icons.SNB_INFO,
      routeToGo: "FormatosExportacionTransferencias",
      procesoExportacionSelected: null,
      procesosExportacion: [],
      isFormValid: false,
      envioConfigId: null,
      previousValue: null,
      rules: rules,
      configs: [
        {
          text: "Tipo",
          align: "start",
          value: "tipoNombre",
          sortable: false,
        },
        { text: "Campo", value: "campoNombre", sortable: false },
        { text: "Valor", value: "campoValor", sortable: false },
        { text: "Descripción", value: "campoDescripcion", sortable: false },
      ],
      detalleConfig: [],
      openModalEdit: false,
      campoValorToEdit: null,
      idToEdit: null,
      modulo: "P",
      tipoValores: [],
      tipoValorSelected: null,
      loadingSaveBtn: false,
      isSeparador: false,
    };
  },
  methods: {
    ...mapActions({
      getProcesosEnviosEstandarByModulo:
        "proveedores/getProcesosEnviosEstandarByModulo",
      getEnvioConfiguracionOrdenesPagoById:
        "proveedores/getEnvioConfiguracionOrdenesPagoById",
      getProcesoEnvioOrdenesPagoDetalleById:
        "proveedores/getProcesoEnvioOrdenesPagoDetalleById",
      saveEnvioConfiguracionOrdenesPago:
        "proveedores/saveEnvioConfiguracionOrdenesPago",
      getTiposValoresModuloProveedores:
        "proveedores/getTiposValoresModuloProveedores",
      getVariablesEnviosEstandarByModulo:
        "proveedores/getVariablesEnviosEstandarByModulo",
      setAlert: "user/setAlert",
    }),
    openEditDialog(originalValue) {
      this.previousValue = originalValue;
    },
    setFormatoConfigById() {
      setTimeout(async () => {
        const response = await this.getEnvioConfiguracionOrdenesPagoById(
          this.envioConfigId
        );
        this.tipoValorSelected = response.tvId;
        this.procesoExportacionSelected = this.procesosExportacion.find(
          (x) => x.id == response.procesoEnvioEstandarId
        );
        this.detalleConfig = response.procesoEnvioEstandarDetalle;
      }, 100);
    },
    async setSelects() {
      const response = await this.getTiposValoresModuloProveedores();
      this.tipoValores = response;
      const procesosEnvios = await this.getProcesosEnviosEstandarByModulo(
        this.modulo
      );
      this.procesosExportacion = procesosEnvios;
      const selectValor = await this.getVariablesEnviosEstandarByModulo(
        this.modulo
      );
      selectValor.forEach((x) => {
        this.selectValor.push(x.value);
      });
    },
    updateProcesoEnvioDetalle() {
      if (
        this.procesoExportacionSelected !== null &&
        this.procesoExportacionSelected !== undefined
      )
        this.setProcesoExportacionDetalle();
    },
    async setProcesoExportacionDetalle() {
      const response = await this.getProcesoEnvioOrdenesPagoDetalleById(
        this.procesoExportacionSelected.id
      );
      this.detalleConfig = response;
    },
    async saveFormatoConfig() {
      this.loadingSaveBtn = true;
      const data = {
        envioConfiguracionEstandarId: this.envioConfigId,
        tvId: this.tipoValorSelected,
        procesoEnvioEstandarId: this.procesoExportacionSelected.id,
        procesoEnvioEstandarDetalle: this.detalleConfig,
      };
      try {
        const response = await this.saveEnvioConfiguracionOrdenesPago(data);
        if (response.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.$router.push({ name: "FormatosExportacionTransferencias" });
        }
      } catch (error) {
        this.loadingSaveBtn = false;
      }
    },
    openModal(item) {
      this.campoValorToEdit = item.campoValor;
      this.previousValue = item.campoValor;
      this.idToEdit = item.id;
      this.isSeparador = item.campoNombre == "Separador" ? true : false;
      this.openModalEdit = true;
    },
    cancelEditValor() {
      const indice = this.detalleConfig.findIndex(
        (x) => x.id === this.idToEdit
      );
      const result = this.detalleConfig.find((x) => x.id === this.idToEdit);
      result["campoValor"] = this.previousValue;
      this.detalleConfig.splice(indice, 1, result);
      this.openModalEdit = false;
    },
    saveEditValor() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const indice = this.detalleConfig.findIndex(
          (x) => x.id === this.idToEdit
        );
        const result = this.detalleConfig.find((x) => x.id === this.idToEdit);
        result["campoValor"] = this.campoValorToEdit;
        this.detalleConfig.splice(indice, 1, result);
        this.openModalEdit = false;
        this.setAlert({ type: "success", message: "Actualizado con éxito" });
      }, 100);
    },
  },
};
</script>

<style scope></style>
